<!-- <span class="created-by">
    © 2022-2024 <b><a href="https://apitech.net/" target="_blank">Apitech</a></b> - Tous droits réservés - Mentions légales
</span> -->
<!--
<div class="socials">
    <a href="#" target="_blank" class="ion ion-social-github"></a>
    <a href="#" target="_blank" class="ion ion-social-facebook"></a>
    <a href="#" target="_blank" class="ion ion-social-twitter"></a>
    <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
</div>
-->
<span class="app-version">
    Plan B v1.0.2
</span>
