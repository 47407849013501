import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertLevelEnum } from '../../../Models/enums/alertUsers.enum';
import { SnapshotStatus } from '../../../Models/enums/snapshotStatus';
import { SmsHeaderEnum } from '../../../Models/enums/smsHeader';
import { NbTagComponent, NbTagInputAddEvent, NbDialogRef } from '@nebular/theme';
import { environment } from '../../../../environments/environment';



@Component({
  selector: 'ngx-launch-dialog',
  templateUrl: './launch-dialog.component.html',
  styleUrls: ['./launch-dialog.component.scss']
})
export class LaunchDialogComponent {
  
  status = 'primary';

  ALERT_USER_ID : string = environment.ALERT_USER_ID;
  IMPORT_LDAP_ID : string = environment.IMPORT_LDAP_ID;

  @Input() connector: any;
  @Input() depends_on: any;
  @Input() tags: any;
  /** variable set to true when the connector to run is 'alert users' */
  @Input() alertLevel: boolean;
  options: string[] = ['Niveau 0','Niveau 1','Niveau 2','Niveau 3'];
  smsHeaderOptions = Object.values(SmsHeaderEnum);

  /** mails recipients addresses */
  recipients: Set<string>;

  /** a boolean variable to store the value of send mail checkbox */
  recipientsCheckBoxValue: boolean = true;

  form = new FormGroup({
    alertLevel: new FormControl(''),
    depends_on: new FormArray([]),
    sms_header: new FormControl(''),
    comment: new FormControl(''),
  });

  public constructor(protected ref: NbDialogRef<LaunchDialogComponent>){

  }

  ngOnInit(): void {
    this.recipients = new Set<string>(this.connector.recipients);

    const formArray = this.form.get('depends_on') as FormArray;
    for(let serviceWithSnaphsots of this.depends_on){
      formArray.push(new FormGroup({
        service: new FormControl(serviceWithSnaphsots.service_id),
        // checked: new FormControl(true),
        snapshot: new FormControl(''),
      }));
    }
    if(this.connector.connector_id == this.IMPORT_LDAP_ID){
      this.form.addControl('tag', new FormControl('', Validators.required));

      const initialRecipientControls = Array.from(this.recipients).map(recipient => new FormControl(recipient));
      this.form.setControl('recipientsControl', new FormArray(initialRecipientControls, Validators.required));
      // this.form.addControl('recipientsControl', new FormArray([], Validators.required));
      this.form.addControl('sendMail', new FormControl(true));
    }
  }

  toggleRecipients() {
    const recipientsControl = this.form.get('recipientsControl');
    if (recipientsControl) {
      this.recipientsCheckBoxValue = this.form.get('sendMail')?.value;
      if (this.recipientsCheckBoxValue) {
        recipientsControl.enable();
        recipientsControl.setValidators(Validators.required); // Ensure validators are set when enabled
        recipientsControl.updateValueAndValidity();
      } else {
        recipientsControl.disable();
        recipientsControl.clearValidators(); // Clear validators when disabled
        recipientsControl.updateValueAndValidity()
      }
    }
  }

  get recipientsControlValue(): FormArray {
    return this.form.get('recipientsControl') as FormArray;
  }

  onTagAdd(event: NbTagInputAddEvent): void {
    const { value, input } = event;
    // Check if the recipient already exists in the FormArray
    const recipientExists = this.recipientsControlValue.controls.some(control => control.value === value);
    if (!recipientExists) {
      if (value && this.isValidEmail(value)) {
        this.recipientsControlValue.push(new FormControl(value));
      }
    }
    input.nativeElement.value = '';
  }

  onTagRemove(event: any): void {
    const index = this.recipientsControlValue.controls.findIndex(control => control.value === event.text);
    if (index >= 0) {
      this.recipientsControlValue.removeAt(index);
    }
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  submit(){
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.status = 'danger'
      // this.ref.close()
    }

  }

  cancel(){
    this.ref.close();
  }

  onChange(event: any){
    if(this.form.get('alertLevel').valid){
      this.status = 'success';
    }
    else{
      this.status = 'danger';
    }
  }
}
