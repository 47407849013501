import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ConnectorService } from '../../../Services/Connector/connector.service';
import { NbDialogService } from '@nebular/theme';
import { PlanificationDialogComponent } from '../planification-dialog/planification-dialog.component';
import { environment } from '../../../../environments/environment';
import { FileUploadModalComponent } from '../../file-upload-modal/file-upload-modal.component';
import { ExecutingRequestComponent } from '../../executing-request/executing-request.component';
import { SuccessHandlerService } from '../../../Services/successHandler/success-handler.service';
import { ErrorHandlerService } from '../../../Services/errorHandler/error-handler.service';
import { ReportService } from '../../../Services/report/report.service';
import { ReportComponent } from '../../report/report.component';
import { UpdateMailModalComponent } from '../../update-mail-modal/update-mail-modal.component';
import { PurgeReportsComponent } from '../../purge-reports/purge-reports.component';


@Component({
  selector: 'ngx-sub-component',
  templateUrl: './sub-component.component.html',
  styleUrls: ['./sub-component.component.scss']
})
export class SubComponentComponent implements OnInit {
    @Output() launch = new EventEmitter();
    @Output() stop = new EventEmitter();
    @Output() updateRecipients = new EventEmitter();
    /** an input for the current service */
    @Input() connector: any;

    /** connector logs */
    logs: any

    /** connector reports */
    reports: any


    organization_id= environment.ORGANIZATION_ID;

    /** a variable to check if a connector is running or not */
    // is_running: boolean;

    /** a variable for storing the connector's status */
    // status: boolean;

    /** Alert users connector id */
    ALERT_USER_ID : string = environment.ALERT_USER_ID;

    /** Import users connector id */
    IMPORT_LDAP_ID: string = environment.IMPORT_LDAP_ID;

  constructor(private connectorService: ConnectorService, private dialogService: NbDialogService,
    private successHandler: SuccessHandlerService, private errorHandler: ErrorHandlerService,
    private reportService: ReportService){
  }

  ngOnInit(): void {
    this.reportService.getConnectorReports(this.organization_id, this.connector.connector_id).subscribe((response) => {
      this.reports = response;
    });
    
    this.connectorService.getLogs(this.organization_id, this.connector.connector_id).subscribe((response) => {
      this.logs = response;
    });

  }

  uploadFile(){
    this.dialogService.open(FileUploadModalComponent)
    .onClose.subscribe((file) => {
      if(file){
        let dialogRef = this.showAlertingUserModal(`Envoie du fichier en cours ...`);
        this.connectorService.uploadFile(this.organization_id, file).subscribe((response) =>{
          dialogRef.close();
          this.successHandler.handleSuccess('Opération terminée avec succès.')
        }, error =>{
          dialogRef.close();
          this.errorHandler.handleError(error)
        });
      }
    })
  }

  showReports(){
    this.dialogService.open(ReportComponent,{
      context: {
        connector: this.connector,
        reports: this.reports,
      }
    });
  }

  purgeReports(){
    this.dialogService.open(PurgeReportsComponent).onClose.subscribe(formData => {
      if(formData){
        let dialogRef = this.showAlertingUserModal(`Nettoyage des rapports en cours ...`);
        this.reportService.purgeReports(this.organization_id, this.connector.connector_id, formData.numberOfReportsToKeep).subscribe(deletedReports =>{
          dialogRef.close();
          this.successHandler.handleSuccess(`${deletedReports} rapport(s) ont été supprimés avec succès`);
        }, error =>{
          dialogRef.close();
          this.errorHandler.handleError(error)
        });
      }
    })
  }

  getConnector(){
    const data = this.connectorService.getConnector(this.organization_id, this.connector.connector_id);
  }

  getstatusString(status: number){
    switch (status){
      case 0:
        return 'erreur'
      case 1:
        return 'OK';

    }
  }

  planify(){
    this.dialogService.open(PlanificationDialogComponent,
      {
        context: {
          connector: this.connector,
        },
      }
    ).onClose.subscribe((formData) => {
      console.log(formData)
    })
  }

  getConnectorLogs(){
    this.connectorService.getLogs(this.organization_id, this.connector.connector_id).subscribe((response) => {
      this.logs = response;
    })
  }

   /**
   *   a function that opens a pop-up if one user or more are alerting
   */
   showAlertingUserModal(message: string){
    return this.dialogService.open(ExecutingRequestComponent, {
      context: {
        message
      },
      closeOnBackdropClick: false,  // Désactive la possibilité de fermer en cliquant en dehors du dialogue
    });
  }

}
