import { Component } from '@angular/core';
import { ConnectorService } from '../../Services/Connector/connector.service';
import { NbDialogService, NbMenuService } from '@nebular/theme';
import { interval } from 'rxjs';
import { switchMap, takeWhile, timeout } from 'rxjs/operators';
import { ShowcaseDialogComponent } from '../../pages/modal-overlays/dialog/showcase-dialog/showcase-dialog.component';
import { LaunchDialogComponent } from './launch-dialog/launch-dialog.component';
import { AlertLevelEnum } from '../../Models/enums/alertUsers.enum';
import { ErrorHandlerService } from '../../Services/errorHandler/error-handler.service';
import { environment } from '../../../environments/environment';
import { ServicesService } from '../../Services/services/services.service';
import { RunConnectorDto } from '../../Models/connector/startConnector-dto';
import { TagService } from '../../Services/tag/tag.service';
import { KeycloakSecurityService } from '../../Keycloak-config/keycloak-security.service';
import { TagType } from '../../Models/enums/tagType';
import { UpdateMailModalComponent } from '../update-mail-modal/update-mail-modal.component';
import { ExecutingRequestComponent } from '../executing-request/executing-request.component';
import { SuccessHandlerService } from '../../Services/successHandler/success-handler.service';

@Component({
  selector: 'ngx-connector',
  templateUrl: './connector.component.html',
  styleUrls: ['./connector.component.scss']
})
export class ConnectorComponent {

   /** a variable storing the Client identifier */
   organization_id: string = environment.ORGANIZATION_ID;

   /** a variable storing the services */
   connectors: any;
 
   /** a variable set to true while creating/launching a service */
   iscreating: boolean;
 
     /** a variable set to true while deleting/stoping a service */
   isdeleting: boolean;
 
   //a variable to check whether all service started or not
   allStarted: boolean ;
 
   /** a variable storing the list of down services */
   downServices: any;

   /** a variable for tags */
   tags: any

    /** Import ldap connector id */
    ALERT_USER_ID : string = environment.ALERT_USER_ID;

   /** Import ldap connector id */
   IMPORT_LDAP_ID : string = environment.IMPORT_LDAP_ID;

   constructor(private connectorService: ConnectorService, 
                private dialogService: NbDialogService, 
                private errorhandler: ErrorHandlerService,
                private serviceService: ServicesService,
                private tagService: TagService,
                private securityService: KeycloakSecurityService,
                private successHandler: SuccessHandlerService
                ) { }

  ngOnInit(): void {
    const eventSource = new EventSource('/events');
    eventSource.addEventListener('message', (event) => {
      this.connectors = event.data
    });
    this.getConnectors().subscribe((response) => {
      this.connectors = response;

      interval(10*1000)
      .pipe(
        takeWhile(() => this.checkStoppedCreating()), // continue while condition is true
        switchMap(() => this.getConnectors()),
      )
      .subscribe(response => {
        this.connectors = response;
      });
      })
    this.getTags();
  }


  getConnectors(){
    const observable = this.connectorService.GetConnectors(this.organization_id);
    return observable;
  }

  getTags(){
    return this.tagService.getTags(this.organization_id).subscribe(response =>{
      this.tags = response;
      // filter source tag if user is not admin
      if(this.tags?.length > 0){
        if(!(this.checkIsUserAdmin())){
          this.tags = this.tags.filter(tag => tag.type !== TagType.SOURCE)
        }
      }
    });
  }

  updateRecipients(connector: any){
    this.dialogService.open(UpdateMailModalComponent,{
      context: {
        connector: connector
      }
    }).onClose.subscribe(data =>{
      if(data){
        // construct array from Set
        const recipients = [...data];
        let dialogRef = this.showAlertingUserModal();
        this.connectorService.updateRecipientsConnector(this.organization_id, connector.connector_id, recipients).subscribe(response =>{
          // update recipient on client side 
          this.replaceConnector(response)
          dialogRef.close();
          this.successHandler.handleSuccess('Opération terminée avec succès.')
        }, error =>{
          dialogRef.close();
          this.errorhandler.handleError(error)
        })
      }
    })
  }

  replaceConnector(object: any){
    this.connectors = this.connectors.map(connector => {
      if(object.connector_id === connector.connector_id)
        return object
      return connector;
    })
  }

  /**
   *   a function that opens a pop-up if one user or more are alerting
   */
  showAlertingUserModal(){
    return this.dialogService.open(ExecutingRequestComponent, {
      context: {
        message: `Opération de mise à jour des destinataires en cours...`
      },
      closeOnBackdropClick: false,  // Désactive la possibilité de fermer en cliquant en dehors du dialogue
    });
  }

  checkIsUserAdmin(){
    if(this.securityService.kc.tokenParsed.realm_access.roles.includes('admin')){
      return true;
    }
    return false;
  }

  /** launch a connector */
  async launch(connector: any){
    let alertLevel = false;
    if(connector.connector_id == this.ALERT_USER_ID){
      alertLevel = true;
    }
    await this.appendSnapshotsToServices(connector.depends_on);
    this.dialogService.open(LaunchDialogComponent,
      {context:
        {
          alertLevel: alertLevel,
          connector: connector,
          depends_on: this.downServices,
          tags:  this.tags
        }
      } 
      )
      .onClose.subscribe((formData) => {
        if (formData) { 
          //send is_creating state to childs
          this.connectors = this.connectors.map((element) =>{
            if(element.connector_id === connector.connector_id){
              // element.is_deleting = true;
              return {...element, is_running : true};
            }
            return element;
          })
          //prepare the dto
          let runConnectorDto = new RunConnectorDto();
          runConnectorDto.services = [];
          if(alertLevel){
            runConnectorDto.alert_level = this.stringToAlertLevelEnum(formData.alertLevel);
            runConnectorDto.sms_header = formData.sms_header;
          }
          else if (connector.connector_id == this.IMPORT_LDAP_ID){
            let tag_ids : string[] = [];
            tag_ids.push(formData.tag);
            runConnectorDto.sendMail = formData.sendMail;
            runConnectorDto.recipients = formData.recipientsControl;

            for(let serviceWithSnapshot of formData.depends_on){
              runConnectorDto.services.push({
                service_id : serviceWithSnapshot.service,
                snapshot_id : serviceWithSnapshot.snapshot,
                tag_ids: tag_ids,
                comment: formData.comment
              });
            }
          }
          else{
            for(let serviceWithSnapshot of formData.depends_on){
              runConnectorDto.services.push({
                service_id : serviceWithSnapshot.service,
                snapshot_id : serviceWithSnapshot.snapshot,
              });
            }
          }
          //run the connector
          this.connectorService.StartConnector(this.organization_id, connector.connector_id, runConnectorDto)
            .subscribe((response) =>{
              this.getConnectors().subscribe((response) =>{
                this.connectors = response;
              }, error =>{
                this.changeConnectorstate(connector, false)
                this.errorhandler.handleError(error);
              })
            }, 
            error =>{
              this.getConnectors().subscribe((response) =>{
                this.connectors = response;
                this.errorhandler.handleError(error);
              }, error =>{
                this.changeConnectorstate(connector, false);
                this.errorhandler.handleError(error);
              })
            }
          )
        }
      });
  }

  /**
   * append snapshot to service to send them to the pop
   * @param depends_on the list of services on which depends the connector 
   * @returns services to start for the connector
   */
  async appendSnapshotsToServices(depends_on: any) {
    this.downServices = [];
    const snapshotPromises = depends_on.map(service_id =>
      this.serviceService.getSnapshots(this.organization_id, service_id).toPromise()
    );
    try {
      const responses = await Promise.all(snapshotPromises);
      responses.forEach((response, index) => {
        const snapshots = response;
        const deps = {service_id: depends_on[index], snapshots: snapshots};
        this.downServices.push({...deps});
      });
    } catch (error) {
      console.error('Error fetching snapshots:', error);
      this.errorhandler.handleError(error);
    }
    return this.downServices;
  }

  stringToAlertLevelEnum(niveau: string){
    switch (niveau){
      case 'Niveau 0':
        return AlertLevelEnum.ALERT_LEVEL_0;
      case 'Niveau 1':
        return AlertLevelEnum.ALERT_LEVEL_1;
      case 'Niveau 2':
        return AlertLevelEnum.ALERT_LEVEL_2;
      case 'Niveau 3':
        return AlertLevelEnum.ALERT_LEVEL_3;
    }
  }

  /** launch connector*/
  // start(connector: any){
  //   this.connectors = this.connectors.map((element) =>{
  //     if(element.connector_id === connector.connector_id){
  //       // element.is_deleting = true;
  //       return {...element, is_running : true};
  //     }
  //     return element;
  //   })
  //   this.connectorService.StartConnector(this.organization_id, connector.connector_id).subscribe((response) =>{
  //     this.getConnectors().subscribe((response) =>{
  //       this.connectors = response;
  //     }, error =>{
  //       this.changeConnectorstate(connector, false)
  //       this.errorhandler.handleError(error);
  //     })
  //   }, 
  //   error =>{
  //     this.getConnectors().subscribe((response) =>{
  //       this.connectors = response;
  //       this.errorhandler.handleError(error);
  //     }, error =>{
  //       this.changeConnectorstate(connector, false);
  //       this.errorhandler.handleError(error);
  //     })
  //   }
  //   )
  // }

  stop(connector: any){
    this.connectors = this.connectors.map((element) =>{
      if(element.connector_id === connector.connector_id){
        // element.is_deleting = true;
        return {...element, is_running : true};
      }
      return element;
    })

    // this.connectorService.StopConnector(this.organization_id, connector.connector_id).subscribe((response) =>{
    //   this.getConnectors().subscribe((response) =>{
    //     this.connectors = response;
    //   }, error =>{
    //     this.launchError(error.message);
    //   })
    // }, error =>{
    //   this.launchError(error.message);
    // })
  }

  launchError(message: string){
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: 'Erreur',
        message : message,
      },
    });
  }
  
    /**
     * function that checks if services are stopped creating/deleting or not
     * @returns true if at least one service is creating/deleting, false otherwise
     */
    checkStoppedCreating(){
      for(let connector of this.connectors ){
        if(connector.is_running){
          return true;
        }
      }
      return false;
    }

/**
 * change the connector is_running property
 * @param connector 
 * @param is_running 
 */
    changeConnectorstate(connector: any, is_running: boolean){
      this.connectors = this.connectors.map((element) =>{
        if(element.connector_id === connector.connector_id){
          // element.is_deleting = true;
          return {...element, is_running : is_running};
        }
        return element;
      })
    }
}
