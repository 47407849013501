<div class="conrainer-fluid" (click)="open()">
    <div class="row header" [ngClass]="{'expanded': expanded}">
        <div class="col-11">Historique </div>
        <div class="col-1 icons">
            <i class="fa fa-chevron-down colorChevron float-right" aria-hidden="true" *ngIf="!expanded"></i>
            <i class="fa fa-chevron-up colorChevron float-right" aria-hidden="true" *ngIf="expanded"></i>
        </div>
    </div>
</div>
<div class="container-fluid body" id="scrollStyle" *ngIf="expanded">
    <div *ngFor="let log of logs" class="margin row">
        <span class="col-2">{{log.timestamp | date:"dd/MM/yy HH:mm:ss"}} </span>
        <span>{{log.message}}</span>
        <!-- <ngx-sub-component [snapshot]="log" (delete)="delete(snapshot.snapshot_id)"></ngx-sub-component> -->
    </div>
</div>
